<template>
  <router-view></router-view>
</template>

<script>
import { onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";
export default {
  name: "Journal",
  setup() {
    const store = useStore();
    onMounted(() => {
      store.commit("component/SET_LOADER", false);
    });
  },
};
</script>
